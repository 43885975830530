<template>
  <div class="home">
    <div class="topinfo">
      <div class="user" @click="showLogout">
        <img src="./../assets/header-img.png" alt="" />
        <span>{{ userInfo.username }}</span>
      </div>
      <div v-if="showPopup" class="popup">
        <div class="item1" @click="updatePassword">
          <img src="./../assets/password-icon.png" alt="" />
          <span>修改密码</span>
        </div>
        <div class="item2" @click="logout">
          <img src="./../assets/logout.png" alt="" />
          <span>退出</span>
        </div>
      </div>
    </div>
    <div v-if="role === 1" class="menu1">
      <div
        v-for="item in menuList"
        :key="item.id"
        v-show="item.type !== 3"
        class="menu1-item"
        @click="handleNav(item.path)"
      >
        <img :src="item.icon ? require(`@/assets/${item.icon}`) : img" alt="" />
        <span>{{ item.name }}</span>
      </div>
      <!-- <div class="menu1-item" @click="handleNav('account/**')">
        <img src="@/assets/menu1.png" alt="" />
        <span>账号管理</span>
      </div>
      <div class="menu1-item" @click="handleNav('role/**')">
        <img src="@/assets/menu2.png" alt="" />
        <span>角色管理</span>
      </div>
      <div class="menu1-item" @click="handleNav('menuManage/**')">
        <img src="@/assets/menu3.png" alt="" />
        <span>菜单管理</span>
      </div> -->
    </div>
    <div v-if="role === 3" class="menu2">
      <div class="item1" @click="showAccountPopup">新建本部门用户</div>
      <div class="item2" @click="handleNav('audituser/**')">查看本部门用户</div>
    </div>
    <div v-if="role === 4" class="menu2">
      <div
        class="item1"
        v-for="item in menuList"
        :key="item.id"
        v-show="item.type !== 3"
        @click="handleNav(item.path)"
      >
        {{ item.name }}
      </div>
      <!-- <div class="item1" @click="handleNav('department/**')">部门编码管理</div>
      <div class="item2" @click="handleNav('personnel/**')">人员管理</div> -->
    </div>
    <div v-if="role === 2" class="menu2">
      <div class="item1" @click="showApply = true">新建重置</div>
      <div class="item2" @click="showReset = true">新建迁移</div>
    </div>
    <div v-if="role === 1" class="func">
      <!-- <div class="btn" v-for="item in menuList" :key="item.id" v-show="item.type===3" @click="handleNav(item.path)">{{ item.name }}</div> -->
      <div class="btn" @click="handleNav('adminApplyResetList/**')">
        所有重置-申请列表
      </div>
      <div class="btn" @click="handleNav('adminApplyMigrationList/**')">
        所有迁移-申请列表
      </div>
    </div>
    <div v-if="role === 3" class="func">
      <!-- <div class="btn" v-for="item in menuList" :key="item.id" v-show="item.type===3" @click="handleNav(item.path)">{{ item.name }}</div> -->
      <div class="btn" @click="handleNav('processReset/**')">重置-审批记录</div>
      <div class="btn" @click="handleNav('processMigration/**')">
        迁移-审批记录
      </div>
    </div>
    <div v-if="role === 4" class="func">
      <!-- <div class="btn" v-for="item in menuList" :key="item.id" v-show="item.type===3" @click="handleNav(item.path)">{{ item.name }}</div> -->
      <div class="btn" @click="handleNav('adminApplyResetList/**')">
        所有重置-申请列表
      </div>
      <div class="btn" @click="handleNav('adminApplyMigrationList/**')">
        所有迁移-申请列表
      </div>
    </div>
    <div v-if="role === 2" class="func">
      <!-- <div class="btn" v-for="item in menuList" :key="item.id" v-show="item.type===3" @click="handleNav(item.path)">{{ item.name }}</div> -->
      <div class="btn" @click="handleNav('ApplyResetList')">重置-申请列表</div>
      <div class="btn" @click="handleNav('ApplyMigrationList')">
        迁移-申请列表
      </div>
    </div>
    <van-overlay :show="showApply">
      <div class="modal">
        <div class="modal-title">
          <img src="@/assets/warning.png" alt="" />
          <span>警告</span>
        </div>
        <div class="modal-body">
          <div class="info1">
            重置后，数据无法恢复，如虚假重置，需 要承担相应责任。
          </div>
          <div class="info2">是否确认对设备进行重装？</div>
        </div>
        <div class="modal-footer">
          <button
            class="btn2"
            style="margin-right: 30px"
            @click="showApply = false"
          >
            取消
          </button>
          <button class="btn1" @click="handleNav('ApplyReset/**')">
            开始重置
          </button>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showReset">
      <div class="modal">
        <div class="modal-title">
          <img src="@/assets/warning.png" alt="" />
          <span>警告</span>
        </div>
        <div class="modal-body">
          <div class="info3">迁移申请审批通过后，才可以进行换板操作</div>
          <div class="info1">
            迁移后，数据无法恢复，如虚假重置，需 要承担相应责任。
          </div>
          <div class="info2">是否确认对设备进行迁移？</div>
        </div>
        <div class="modal-footer">
          <button
            class="btn2"
            style="margin-right: 30px"
            @click="showReset = false"
          >
            取消
          </button>
          <button class="btn1" @click="handleNav('/ApplyMigration/**')">
            开始迁移
          </button>
        </div>
      </div>
    </van-overlay>

    <van-overlay :show="showModal">
      <div class="wrapper" @click.stop>
        <div class="title">{{ title }}</div>
        <!-- <div class="form-item">
          <span class="label">账号:</span>
          <input
            :disabled="title == '编辑账号'"
            @input="verifyUser"
            type="text"
            v-model="model.username"
            placeholder="请输入账号"
          />
        </div> -->
        <div class="form-item">
          <div style="display: flex; align-items: center">
            <span class="label">账号:</span>
            <input
              @input="verifyUser"
              type="text"
              v-model="model.username"
              placeholder="请输入账号"
            />
          </div>

          <div
            v-if="userVerify"
            style="color: red; margin-top: 4px; margin-left: 8px"
          >
            账号不能为空！
          </div>
        </div>
        <!-- <div class="form-item">
          <span class="label">部门:</span>
          <select
            v-model="model.deptId"
            id=""
            :disabled="title == '编辑账号'"
            @change="changeRole"
          >
            <option selected disabled value="" v-show="false">
              请选择部门
            </option>
            <option
              v-for="(item, index) in deptuserList"
              :key="index"
              :value="item.value"
              :label="item.label"
            ></option>
          </select>
        </div> -->
        <!-- <div class="form-item">
          <span class="label">手机号:</span>
          <input
            @input="verifyMobile"
            type="number"
            v-model="model.mobile"
            placeholder="请输入手机号"
          />
        </div> -->
        <div class="form-item">
          <div style="display: flex; align-items: center">
            <span class="label">手机号:</span>
            <input
              @input="verifyMobile"
              type="number"
              v-model="model.mobile"
              placeholder="请输入手机号"
            />
          </div>
          <div
            v-if="mobileVerify"
            style="color: red; margin-top: 4px; margin-left: 8px"
          >
            手机号格式不正确!
          </div>
        </div>
        <!-- <div class="form-item">
          <span class="label">工号:</span>
          <input
            :disabled="title == '编辑账号'"
            @input="verifyWorkNum"
            type="number"
            v-model="model.workNum"
            placeholder="请输入工号"
          />
        </div> -->
        <div class="form-item">
          <div style="display: flex; align-items: center">
            <span class="label">工号:</span>
            <input
              @input="verifyWorkNum"
              type="number"
              v-model="model.workNum"
              placeholder="请输入工号"
            />
          </div>

          <div
            v-if="workNumVerify"
            style="color: red; margin-top: 4px; margin-left: 8px"
          >
            工号不能为空！
          </div>
        </div>
        <!-- <div class="form-item">
          <span class="label">角色:</span>
          <select v-model="model.roleId" id="" @change="changeRole">
            <option selected disabled value="" v-show="false">
              请选择角色
            </option>
            <option
              v-for="(item, index) in roleList"
              :key="index"
              :value="item.value"
              :label="item.label"
            >{{ item.label }}</option>
          </select>
        </div> -->
        <div class="form-item">
          <div style="display: flex; align-items: center">
            <span class="label">角色:</span>
            <select v-model="model.roleId" id="" @change="changeRole">
              <option selected disabled value="" v-show="false">
                请选择角色
              </option>
              <option
                v-for="(item, index) in roleList"
                :key="index"
                :value="item.value"
                :label="item.label"
              >
                {{ item.label }}
              </option>
            </select>
          </div>

          <div
            v-if="roleVerify"
            style="color: red; margin-top: 4px; margin-left: 8px"
          >
            角色不能为空！
          </div>
          <!-- <input type="text" v-model="model.roleName" placeholder="请输入角色"> -->
        </div>
        <!-- <div v-if="title !== '编辑账号'" class="form-item">
          <span class="label">初始密码:</span>
          <input
            @input="verifyPassWord"
            type="text"
            v-model="model.password"
            placeholder="请输入初始密码"
          />
        </div> -->
        <div v-if="title !== '编辑账号'" class="form-item">
          <div style="display: flex; align-items: center">
            <span class="label">初始密码:</span>
            <input
              type="text"
              v-model="model.password"
              placeholder="请输入初始密码"
            />
          </div>
        </div>
        <div
          v-if="passwordVerify"
          style="color: red; margin-top: 4px; margin-left: 8px"
        >
          密码不能包含汉字，必须包含字母、数字，长度在8到20个字符之间
        </div>
        <div class="form-item">
          <div style="display: flex; align-items: center">
            <span class="label">账号状态:</span>
            <van-radio-group v-model="model.status" direction="horizontal">
              <van-radio :name="1">启用</van-radio>
              <van-radio :name="0">禁用</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="btnbox2">
          <button class="btn2" @click="cancel">取消</button>
          <button class="btn1" @click="confirm">保存</button>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { userSave, getListRole, getDep } from "@/api/personnel";
import { getMenuList } from "@/api/menu";
import { showToast } from "vant";
export default {
  name: "HomeView",
  components: {
    // HelloWorld
  },
  /**
   * role
   * 1 超级管理员
   * 4 总部
   * 3 审批人
   * 2 申请人
   */
  data() {
    return {
      img: require("../assets/menu1.png"),
      title: "新建本部门用户",
      showPopup: false,
      role: 1,
      userInfo: {},
      showApply: false,
      showReset: false,
      showModal: false,
      model: {
        mobile: "",
        password: "",
        status: 1,
        username: "",
        workNum: "",
        roles: [],
        roleId: "",
        deptId: "",
      },
      roleName: "",
      roleList: [],
      roles: [],
      verify: true,
      deptuserList: [],
      menuList: [],
      userVerify: false,
      workNumVerify: false,
      deptVerify: false,
      mobileVerify: false,
      roleVerify: false,
      passwordVerify: false,
      roleCode: "",
    };
  },
  watch: {
    "model.password": {
      handler(val) {
        if (val) {
          this.verifyPassWord();
        }
      },
    },
  },

  created() {


    if (localStorage.getItem("user")) {
      this.userInfo = JSON.parse(localStorage.getItem("user"));
      this.role = this.userInfo.roles[0];
      this.roleCode = JSON.parse(localStorage.getItem("user")).roleCodes[0];
    }

    this.getMenu();
  },
  mounted() {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', () => {
          history.pushState(null, null, document.URL)
      })
  },

  methods: {
    verifyPassWord() {
      // 密码要求包含字母、数字和特殊字符，长度在8到20个字符之间
      const regex = /^[a-zA-Z0-9]{8,20}$/;
      // if(this.model.password.length>8){
      if (!regex.test(this.model.password)) {
        // showToast(
        //   "密码不能包含汉字，必须包含字母、数字，长度在8到20个字符之间"
        // );
        this.passwordVerify = true;
      } else {
        this.passwordVerify = false;
      }
      // }
      // if (!regex.test(this.model.password)) {
      //   // showToast(
      //   //   "密码不能包含汉字，必须包含字母、数字，长度在8到20个字符之间"
      //   // );
      //   this.passwordVerify = true;
      // } else {
      //   this.verify = false;
      // }
    },
    verifyMobile() {
      const regex =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!regex.test(this.model.mobile)) {
       
        this.mobileVerify = true;
      } else {
        this.mobileVerify = false;
      }
    },
    verifyUser() {
      // const regex = /^[a-zA-Z0-9]+$/
      if (this.model.username.length === 0) {
        console.log(123456, this.model);
        // showToast("账号不能为空！")
        this.userVerify = true;
      } else {
        this.userVerify = false;
      }
    },
    verifyWorkNum() {
      console.log(123456, this.model.username);
      if (this.model.workNum.length === 0) {
        // showToast("工号不允许为空");
        this.workNumVerify = true;
      } else {
        this.workNumVerify = false;
      }
    },
    // 获取菜单
    getMenu() {
      getMenuList({ lazy: false, parentId: 0 }).then((res) => {
        if (res.code === 1) {
          console.log(res);
          this.menuList = res.data.sort((a, b) => {
            return a.sort - b.sort;
          });

          // this.currentPage=res.data.totalPages
        }
      });
    },
    // 审核人新建部门用户弹框
    showAccountPopup() {
      this.showModal = true;
      this.model = {
        mobile: "",
        password: "",
        status: 1,
        username: "",
        workNum: "",
        roles: [],
        roleId: "",
        deptId: "",
      };
      getDep().then((res) => {
        // console.log("部门列表", res.data);
        this.deptuserList = [];
        if (res.code === 1) {
          res.data.forEach((item) => {
            this.deptuserList.push({ value: item.id, label: item.name });
          });
        }
      });
      getListRole({ roleCode: this.roleCode }).then((res) => {
        this.roleList = [];
        if (res.code === 1) {
          console.log(res);
          this.roles = res.data;
          //   this.roleList=res.data
          res.data.forEach((item) => {
            this.roleList.push({ value: item.id, label: item.roleName });
          });
          // console.log("角色信息", this.roleList);
        }
      });
    },
    changeRole() {
      this.model.roles = [];
      // console.log("this.model.roleId", this.model.roleId);
      this.roles.forEach((item) => {
        // console.log("item.id", item.id);
        if (item.id === this.model.roleId) {
          item.menuIds = [];
          delete item.createTime;
          delete item.updateTime;
          delete item.type;
          delete item.delFlag;
          delete item.delTime;
          this.roleId = item.id;
          this.model.roleIds = [`${item.id}`];
          this.model.roles.push(item);
        }
      });
      // console.log("角色信息", this.model.roles);
      this.roleVerify = false;
    },
    // 点击用户
    showLogout() {
      this.showPopup = true;
    },
    // 退出登录
    logout() {
      const params = {
        mobile: this.userInfo.mobile,
      };
      this.$store.dispatch("user/logout", params).then((res) => {
        console.log("退出登录返回值", res);
        // if (res.code === 1) {
        this.$router.push("/");
        // }
      });
    },
    // 修改密码
    updatePassword() {
      this.$router.push("updatePassword");
    },
    handleNav(path) {
      this.$router.push(path);
    },
    // 弹窗取消
    cancelOverlay() {
      this.showApply = false;
      this.showPopup = false;
    },
    cancel() {
      this.showModal = false;
    },
    confirm() {
      delete this.model.roles;
      if (this.model.username.length == 0) {
        this.userVerify = true;
      }
      if (this.model.workNum.length == 0) {
        this.workNumVerify = true;
        // deptVerify:false,
        // mobileVerify:false,
        // roleVerify:false,
        // passwordVerify:false
      }
      if (this.model.roleId.length == 0) {
        this.roleVerify = true;
      }
      if (this.model.mobile.length == 0) {
        this.mobileVerify = true;
      }
      if (this.model.password.length == 0) {
        this.passwordVerify = true;
      }
      if (
        this.userVerify == false &&
        this.workNumVerify == false &&
        this.mobileVerify == false &&
        this.roleVerify == false &&
        this.passwordVerify == false
      ) {
        userSave(this.model).then((res) => {
          console.log(res);
          if (res.code === 1) {
            showToast("添加成功");
            this.showModal = false;
          }
        });
      }
    },
    // verifyPassWord() {
    //   // 密码要求包含字母、数字和特殊字符，长度在8到20个字符之间
    //   const regex = /^[a-zA-Z0-9]{8,20}$/;
    //   if (!regex.test(this.model.password)) {
    //     showToast(
    //       "密码不能包含汉字，必须包含字母、数字，长度在8到20个字符之间"
    //     );
    //     this.verify = false;
    //   } else {
    //     this.verify = true;
    //   }
    // },
    // verifyMobile() {
    //   const regex =
    //     /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    //   if (!regex.test(this.model.mobile)) {
    //     showToast("手机号格式不正确！请重新输入");
    //     this.verify = false;
    //   } else {
    //     this.verify = true;
    //   }
    // },
    // verifyUser() {
    //   // const regex = /^[a-zA-Z0-9]+$/
    //   if (this.model.username.length === 0) {
    //     showToast("账号不能为空！");
    //     this.verify = false;
    //   } else {
    //     this.verify = true;
    //   }
    // },
    // verifyWorkNum() {
    //   if (this.model.workNum.length === 0) {
    //     showToast("工号不允许为空");
    //     this.verify = false;
    //   } else {
    //     this.verify = true;
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  .wrapper {
    width: 596px;
    min-height: 200px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 50px;
    font-size: 16px;
    .btnbox2 {
      width: 100%;
      text-align: center;
      margin-top: 28px;
    }
    .title {
      font-size: 32px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #000000;
      text-align: center;
      margin-bottom: 40px;
    }
    .form-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      .label {
        font-size: 28px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        color: #000000;
        margin-right: 20px;
        display: inline-block;
        width: 140px;
        text-align: right;
        //   text-align: left;
      }
      input {
        width: 326px;
        height: 60px;
        border-radius: 10px;
        font-size: 26px;
        border: 2px solid #999999;
      }
      select {
        width: 334px;
        height: 60px;
        border-radius: 10px;
        font-size: 26px;
        border: 2px solid #999999;
      }
    }
  }
}
.topinfo {
  width: 750px;
  height: 236px;
  background-image: url("@/assets/homg-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-sizing: border-box;
  padding-right: 32px;

  .user {
    font-size: 28px;
    color: #ffffff;
    display: flex;
    align-items: center;
    // padding-right: 32px;
    justify-content: flex-end;
    padding-top: 66px;

    img {
      width: 60px;
      margin-right: 10px;
    }
  }
  .popup {
    width: 330px;
    height: 62px;
    background-image: url("./../assets/logout-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;
    position: relative;
    z-index: 100;
    .item1 {
      display: flex;
      align-items: center;
      margin-top: 6px;
      img {
        width: 44px;
        margin-right: 10px;
      }
    }
    .item2 {
      display: flex;
      margin-top: 6px;
      align-items: center;
      img {
        width: 32px;
        margin-right: 10px;
      }
    }
  }
}
.menu1 {
  width: 686px;
  height: 208px;
  background: #ffffff;
  box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  // justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 32px;
  margin-top: -74px;
  padding: 30px 50px;
  box-sizing: border-box;
  .menu1-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.33%;
    font-size: 32px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    img {
      width: 100px;
      margin-bottom: 16px;
    }
  }
}
.menu2 {
  margin-top: -74px;
  display: flex;
  justify-content: space-around;
  color: #ffffff;
  font-size: 28px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  .item1 {
    width: 264px;
    height: 120px;
    background: linear-gradient(321deg, #4261ff 0%, #8ea1ff 100%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item2 {
    width: 264px;
    height: 120px;
    background: linear-gradient(151deg, #7dd4ff 0%, #3fbdfb 100%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.func {
  .btn {
    margin-left: 32px;
    margin-top: 100px;
    width: 686px;
    height: 120px;
    background: linear-gradient(136deg, #3fbdfb 0%, #4261ff 100%);
    border-radius: 10px;
    color: #ffffff;
    font-size: 28px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.modal {
  width: 570px;
  // height: 420px;
  background: #ffffff;
  border-radius: 10px;
  padding: 50px 32px;
  box-sizing: border-box;
  .modal-title {
    text-align: center;
    color: #ff333d;
    font-size: 28px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    img {
      width: 44px;
      margin-right: 20px;
    }
  }
  .modal-body {
    font-size: 28px;
    .info1 {
      color: #333333;
    }
    .info2 {
      color: #ff333d;
      font-weight: 500;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .info3 {
      font-size: 24px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      color: #ffa522;
      margin-bottom: 30px;
    }
  }
  .modal-footer {
    text-align: center;
  }
}
</style>
